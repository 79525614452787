import axios from 'axios';

const addMessage = ({text, email, sendAfter}) => axios.post('https://us-central1-mustqbl.cloudfunctions.net/app/api/addMessage', {
    email, text, sendAfter
  })
  .then((response) => {
    // console.log(response);
  })
  .catch((error) => {
    // console.log(error);
  }
);

const verifyEmail = ({token}) => axios.post('https://us-central1-mustqbl.cloudfunctions.net/app/api/verifyEmail', {
    token
  })
  .then((response) => {
    // console.log(response);
  })
  .catch((error) => {
    // console.log(error);
  }
);

export {addMessage, verifyEmail};